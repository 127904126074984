import React, { useContext } from 'react'
import { AccordionContext, useAccordionButton } from 'react-bootstrap'

const AccordionHeader = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )

  const isCurrentEventKey = activeEventKey === eventKey

  return (
    <h2 className="accordion-header">
      <button
        type="button"
        onClick={decoratedOnClick}
        data-ad-element-type="store product accordion"
        data-ad-text={children}
        data-ad-button-id="version 1"
        data-ad-button-location="body"
        className={`accordion-button ad-el-tracked ${(isCurrentEventKey ? '' : 'collapsed')}`}
      >
        {children}
      </button>
    </h2>
  )
}

export default AccordionHeader
