import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap'
import Glide from '@glidejs/glide'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons'
import RecipeBlock from '../common/recipe-block'

const RecipeBlockContainer = (props) => {
  return props.withSlider === true
    ? (
      <div className={`recipe-glide ${props.sliderItems.length > 3 ? 'large-slider' : 'small-slider'}`} id={`${props.handle}-glide`}>
        <div className="glide__track" data-glide-el="track">
          <div className="glide__slides py-1 py-lg-2">
            {props.children}
          </div>
        </div>
        <div className="glide__arrows" data-glide-el="controls">
          <button className="glide__arrow glide__arrow--left" data-glide-dir="<" style={{ left: '20px' }}>
            <FontAwesomeIcon aria-hidden="true" className="text-dark" icon={faArrowCircleLeft} />
            <span className="visually-hidden-focusable">Previous Slide</span>
          </button>
          <button className="glide__arrow glide__arrow--right text-dark" data-glide-dir=">" style={{ right: '20px' }}>
            <FontAwesomeIcon aria-hidden="true" className="text-dark" icon={faArrowCircleRight} />
            <span className="visually-hidden-focusable">Next Slide</span>
          </button>
        </div>
      </div>
    )
    : (
      <>
        {props.children}
      </>
    )
}

const KitchenRecipeBlocks = (props) => {
  const { title } = props

  const recipes = useStaticQuery(graphql`
    query {
      allWpRecipe(
        sort: { fields: [date], order: DESC }
      ) {
        nodes {
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          slug
          recipes {
            cookTime
            prepTime
            madeWith
            madeWith2 {
              ... on WpCut {
                slug
                title
              }
            }
            serves
            shareEnabled
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 470
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    transformOptions: {
                      fit: COVER, cropFocus: CENTER
                      }
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const isHandle = (arr, totest) => arr.filter((el) => el.recipes.madeWith2.some((item) => totest === item.slug))

  const displaySlider = isHandle(recipes.allWpRecipe.nodes, props.handle)
  const sliderItems = props.handle === 'all' ? recipes.allWpRecipe.nodes : displaySlider
  const [bgClass, setBgClass] = useState('bg-dark text-light')

  let foodSlideshow = {}

  useEffect(() => {
    switch (props.handle) {
      case 'classic-steak':
        setBgClass('bg-dark text-yellow')
        break
      case 'crispy-cutlet':
        setBgClass('bg-warning text-orange')
        break
      case 'classic-cutlet':
        setBgClass('bg-primary text-yellow')
        break
      default:
        setBgClass('bg-dark text-yellow')
        break
    }
  }, [title])

  useEffect(() => {
    const settings = {
      type: 'slider',
      startAt: 0,
      perView: 3,
      gap: 30,
      peek: 225,
      bound: true,
      breakpoints: {
        1399: {
          perView: 3,
          peek: 100,
        },
        991: {
          gap: 20,
          peek: { before: 60, after: 60 },
          perView: 2,
        },
        575: {
          gap: 10,
          peek: { before: 60, after: 60 },
          perView: 1,
        },
      },
    }

    foodSlideshow = new Glide(`#${props.handle}-glide`, settings)
    foodSlideshow.mount()
  }, [props.handle])

  return (
    <div id={`${props.handle}-recipes`}>
      <Container fluid className={`${bgClass} pt-3 recipe-blocks-wrapper g-0`}>
        <Container className="d-flex flex-column flex-md-row justify-content-between align-items-center px-1">
          <h2 className="text-center mb-1 mb-md-2 fw-bolder">
            {title}
          </h2>
          <div className="mb-1">
            <Link
              to="/recipes"
              className="ad-el-tracked text-white border-bottom border-white pb-14 h6"
              data-ad-element-type="text link"
              data-ad-text="View all recipes"
              data-ad-button-id="version 1"
              data-ad-button-location="body"
            >
              View all recipes
            </Link>
          </div>
        </Container>
        <Row className="recipe-blocks g-1 g-lg-2 justify-content-center pb-3">
          <RecipeBlockContainer withSlider handle={props.handle} sliderItems={sliderItems}>
            {
                sliderItems.map((node, index) => {
                  if (node.recipes.madeWith2.some((item) => props.handle === item.slug) || props.handle === 'all') {
                    return (
                      <Col className="recipe-column col-3 col text-center" key={`recipe-block-${index}`}>
                        <RecipeBlock key={`recipe-block-${index}`} node={node} justify="center" />
                      </Col>
                    )
                  }
                  return false
                })
              }
          </RecipeBlockContainer>

        </Row>

      </Container>

    </div>
  )
}

export default KitchenRecipeBlocks
