import * as React from 'react'
import Container from 'react-bootstrap/Container'
import { ButtonGroup, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

import { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Grill from '../../images/icons/grill.svg'
import AirFryer from '../../images/icons/air-fryer.svg'
import Oven from '../../images/icons/oven.svg'
import Skillet from '../../images/icons/skillet.svg'

const HowToCook = (props) => {
  const {
    title, optionATitle, optionAContent, optionBContent, optionBTitle, handle, video,
  } = props

  const [currentContent, setCurrentContent] = useState('optionA')

  const swapContent = (option) => {
    setCurrentContent(option)
  }

  const optionAIcon = optionATitle === 'Grill' ? Grill : AirFryer
  const optionBIcon = optionBTitle === 'Skillet' ? Skillet : Oven

  const videoContainerStyle = {
    // paddingBottom: "177.92%",
    paddingBottom: '76%',
    position: 'relative',
    width: '100%',
    height: 0,
  }
  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '1rem',
  }

  return (
    <Container className="pb-md-1 pb-xl-3">
      <Row className="g-md-3 gx-lg-4 gx-xl-5">
        <Col md={6} className="order-md-1">
          <small className="text-uppercase d-block mb-14">How to cook:</small>
          <h2 className="fw-bolder h1">
            {title}
          </h2>
          <Link to={`/kitchen/#${handle}-recipes`} className="jump-link d-block mb-1 mb-md-0">Jump to recipes</Link>
        </Col>
        <Col md={6} className="order-md-3">
          <div style={videoContainerStyle} className="video-container mb-2 mb-md-0">
            <iframe
              src={`https://player.vimeo.com/video/${video}&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&background=1&controls=0`}
              allow="autoplay;"
              style={iframeStyle}
              title={title}
            />
            <script src="https://player.vimeo.com/api/player.js" />
            { currentContent === 'optionA'
              && (
              <StaticImage
                src="../../images/kitchen/mo-preferred.png"
                quality={95}
                placeholder=""
                alt="Mo Preferred Sticker"
                layout="fixed"
                className="position-absolute mo-preferred"
                width={100}
              />
              )}
          </div>
        </Col>
        <Col md={6} className="order-md-2 d-flex align-items-end">
          <ButtonGroup aria-label="Preparation Methods" className="prep-toggle mb-2 mb-md-0">
            <Button
              variant="outline-light"
              className={`${currentContent === 'optionA' ? 'active' : ''} d-flex align-items-center`}
              size="lg"
              onClick={() => swapContent('optionA')}
            >
              <img src={optionAIcon} alt={`${optionATitle} icon`} className="prep-toggle-icon me-12" />
              {optionATitle}
              {' '}
              <span className="ms-12 fw-normal">(preferred)</span>
            </Button>
            <Button
              variant="outline-light"
              className={`${currentContent === 'optionB' ? 'active' : ''} d-flex align-items-center`}
              size="lg"
              onClick={() => swapContent('optionB')}
            >
              <img src={optionBIcon} alt={`${optionBTitle} icon`} className="prep-toggle-icon me-12" />
              {optionBTitle}
            </Button>
          </ButtonGroup>
        </Col>
        <Col md={6} className="order-md-4">
          {currentContent === 'optionA' && optionAContent}
          {currentContent === 'optionB' && optionBContent}
        </Col>
      </Row>
    </Container>
  )
}

export default HowToCook
