import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import RecipeTimes from './recipe-times'

const RecipeBlock = ({ node, blockStyle, justify }) => {
  const blockPadding = blockStyle === 'flush' ? 'p-0' : 'p-12 p-md-1'
  const imgMargin = blockStyle === 'flush' ? 'mb-14' : 'mb-12 mb-md-1'
  const flex = blockStyle === 'flush' ? '' : 'd-flex'

  return (
    <Link
      to={`/recipes/${node.slug}`}
      data-ad-element-type="recipe tile"
      data-ad-text=""
      data-ad-button-id="version 1"
      data-ad-button-location="body"
    >
      <div className={`recipe-block flex-column justify-content-between align-items-center ${flex} ${blockPadding}`}>
        <div className="content-top w-100">
          <GatsbyImage
            image={getImage(node.featuredImage?.node?.localFile?.childImageSharp?.thumbnailImage) || getImage(node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData)}
            alt={`Meati™ ${node.title} recipe`}
            layout="constrained"
            className={`ratio ratio-4x3 rounded-4 ${imgMargin}`}
            imgClassName="rounded-4"
          />
          <h3 className="mb-12 mb-md-1 fw-bold">{node.title}</h3>
        </div>
        <RecipeTimes prepTime={node.recipes.prepTime} cookTime={node.recipes.cookTime} justify={justify} />
      </div>
    </Link>
  )
}

export default RecipeBlock
