import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Accordion, Container } from "react-bootstrap"
import AccordionHeader from "../utilities/accordion-header"

const TipsAndTricks = () => {

  const wpFAQ = useStaticQuery(graphql`
    query {
      allWpFaq(
        filter: {fAQCategories: {nodes: {elemMatch: {name: {eq: "How to Prep"}}}}}
      ) {
        nodes {
          title
          content
        }
      }
    }
  `)

  const newFAQItems = wpFAQ.allWpFaq.nodes.map((node, index) =>
    <Accordion.Item key={`child-${index}`} eventKey={`child-${index}`}>
      <AccordionHeader eventKey={`child-${index}`} wrapper={'h3'}><h3 className="h4 mb-0 text-primary fw-bold">{node.title}</h3></AccordionHeader>
      <Accordion.Collapse eventKey={`child-${index}`}>
        <div className='accordion-body' dangerouslySetInnerHTML={{ __html: node.content }} />
      </Accordion.Collapse>
    </Accordion.Item>
  )

  return (
    <section className="py-2">
      <Container>
        <h2 className="fw-bolder text-center text-md-start"  style={{fontSize: '6.125vw'}}>tips & tricks</h2>
        <Accordion id="faqAccordion" className="accordion-flush">
          {newFAQItems}
        </Accordion>
      </Container>
    </section>
  )

}

export default TipsAndTricks