import React, { useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'

const QuestionForm = props => {
  const {
    title = 'Submit A Question',
    messageLegend = 'Please enter a message',
    subject = 'FAQ form submission from ',
    buttonText = 'Ask Away',
  } = props

  const [validated, setValidated] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [fnameValue, setFnameValue] = useState('')
  const [lnameValue, setLnameValue] = useState('')
  const [messageValue, setMessageValue] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const processForm = e => {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget

    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(true)
    setFormDisabled(true)

    // to Gorgias
    fetch('/.netlify/functions/gorgias-handler', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: emailValue,
        firstName: fnameValue,
        lastName: lnameValue,
        message: messageValue,
        subject: subject + `${fnameValue} ${lnameValue}`,
        team: 2330
      }),
    })
      .then(response => response.json())
      .then(result => {
        //console.log(result)
      })
      .catch(error => {
        console.error('Error:', error);
      });



    // to Klaviyo

    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      headers: {
        accept: 'application/json, text/plain, */*',
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        g: 'SuZhLx',
        $fields: 'first_name,last_name,Message,$source',
        email: emailValue,
        first_name: fnameValue,
        last_name: lnameValue,
        Message: messageValue,
        $source: 'FAQ Form',
      }),
      method: 'POST',
    })
      .then(() => {
        setShowAlert(true)
      })
      .catch(err => {
        console.error(err)
      })

    // to Klaviyo
    if (window.klaviyo) {
      //console.log(window.klaviyo)
      window.klaviyo.push(['track', 'Requested Support'])
    }

    // to Zend

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    const name = `${fnameValue} ${lnameValue}`

    const raw = JSON.stringify({
      request: {
        requester: {
          name,
          email: emailValue,
        },
        subject: subject + name,
        comment: {
          body: messageValue,
        },
      },
    })

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    fetch('https://meatifoods.zendesk.com/api/v2/requests.json', requestOptions)
      .then(response => {
        response.text()
      })
      .then(result => {
        //console.log(result)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={processForm}
      data-ad-element-type="Question Form"
      data-ad-text={title}
      data-ad-button-location="body"
      id="questionForm"
    >
      <section className="bg-light py-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs="10">
              <h2 className="mb-1 fst-italic text-primary text-center">{title}</h2>
            </Col>
            <Col xs="10" className="border border-2 p-1 border-secondary rounded-5 mb-2">
              <Form.Group className="mb-1 rounded-5">
                <Form.Control
                  required
                  type="text"
                  placeholder="First Name"
                  className="border-0 p-1"
                  onChange={e => setFnameValue(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please enter first name</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Control
                  required
                  type="text"
                  placeholder="Last Name"
                  className="border-0 p-1"
                  onChange={e => setLnameValue(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please enter last name</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  className="border-0 p-1"
                  required
                  value={emailValue}
                  pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0, 61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0, 61}[a-zA-Z0-9])?)+$"
                  onChange={e => setEmailValue(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">Please enter a valid email</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  required
                  as="textarea"
                  rows={6}
                  placeholder={messageLegend}
                  className="border-0 p-1"
                  onChange={e => setMessageValue(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">{messageLegend}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="10" className="text-center">
              <Button
                variant="dark"
                className="btn-lg ms-12"
                type="submit"
                disabled={formDisabled}
                data-ad-element-type="partner form submit"
                data-ad-text="Submit"
                data-ad-button-location="body"
                data-ad-button-id="version 1"
              >
                {buttonText}
              </Button>
              <Alert variant="success" className="mt-1" show={showAlert}>
                Thanks! We'll be in touch.
              </Alert>
            </Col>
          </Row>
        </Container>
      </section>
    </Form>
  )
}

export default QuestionForm

QuestionForm.defaultProps = {
  subject: 'FAQ form submission from ',
}
