import * as React from 'react'

const Bubble = (props) => {
  const { type, text } = props

  return (
    <div className={`bubble bubble-${type}`}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267.01 80.91">
        <path d="M267,40.46c0,22.34-19.93,40.45-44.5,40.45a47.1,47.1,0,0,1-28.32-9.24s0,0,0,0a47.84,47.84,0,0,1-59.32-2.21,47.8,47.8,0,0,1-60.68,1.15,47,47,0,0,1-29.67,10.3C19.93,80.91,0,62.8,0,40.46S19.93,0,44.51,0A46.93,46.93,0,0,1,74.18,10.31a47.78,47.78,0,0,1,60.68,1.14,47.84,47.84,0,0,1,59.32-2.21h0A47.1,47.1,0,0,1,222.51,0C247.08,0,267,18.11,267,40.46Z" />
      </svg>
      <span className="d-flex justify-content-center align-items-center">
        {text}
      </span>
    </div>
  )
}

export default Bubble
