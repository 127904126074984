import React, { useEffect, useState } from 'react'
import { useReadLocalStorage } from 'usehooks-ts'
import Modal from '@mui/material/Modal'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const CookingTipsPopup = ({ onHandleClose }) => {
  const tips = useStaticQuery(graphql`
    query {
      allCookingTipsYaml {
        nodes {
          tip_number
          title
          text
          image_cropped {
            childImageSharp {
              gatsbyImageData(
                width: 100
                aspectRatio: 1
                placeholder: BLURRED
                formats: NO_CHANGE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `)
  const hasClosed = useReadLocalStorage('meati_closed_cooking_tips_modal')
  const [open, setOpen] = useState(!hasClosed)
  const handleClose = () => {
    // close modal
    setOpen(false)
    // set a cookie so we know the user closed the modal
    typeof window !== 'undefined' && localStorage.setItem('meati_closed_cooking_tips_modal', true)
    // Inform parent component that handleClose has been called
    onHandleClose()
  }

  const [seconds, setSeconds] = useState(5)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((seconds) => seconds - 1)
    }, 1000)
    if (seconds === 0) {
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId)
  }, [seconds])

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
  }

  return (
    <Modal
      id="cookingTipsModal"
      open={open}
      onClose={handleClose}
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      className="d-flex align-items-center"
      disableRestoreFocus
    >
      <div className="border border-primary rounded-5 modal-content mx-auto">
        <button className="btn btn-link close position-absolute end-0" onClick={handleClose}>
          {seconds === 0
            ? <FontAwesomeIcon className="fa-2x" icon={faTimes} />
            : formatTime(seconds)}
        </button>
        <div className="px-1 px-lg-2 pt-2">
          <h2 id="modalTitle" className="text-primary mb-2 mt-12 fw-bolder">
            Before Cooking,
            {' '}
            <br />
            Read Me.
          </h2>
          {tips.allCookingTipsYaml.nodes.map((node, index) => (
            <div key={`tip-${index}`} className="d-flex align-items-start text-primary my-2">
              <GatsbyImage
                image={getImage(node.image_cropped)}
                alt={`meati ${node.title} illustration`}
                className="rounded-circle me-1 flex-grow-0 flex-shrink-0"
                imgClassName="rounded-circle"
              />
              <div>
                <div className="d-inline-block rounded text-uppercase px-12 border-primary border mb-12">
                  Tip
                  {' '}
                  {node.tip_number}
                </div>
                <h3>{node.title}</h3>
                <p className="subtitle mb-0 lh-sm">{node.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default CookingTipsPopup
