import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '@mui/material/Modal'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import PlayCircle from '../../images/icons/play-circle.svg'
import PlayOutline from '../../images/icons/play-outline.svg'

const KitchenHero = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const iframeStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }

  return (
    <header id="kitchenHero" className="bg-warning px-1 px-md-0">
      <div className="container py-2 py-md-3">
        <Row>
          <Col md={6} className="d-flex align-items-center mb-1">
            <div className="px-xxl-2 text-center text-md-start">
              <h1 className="fw-bold fst-italic fst mb-xxl-2">
                Cook Meati™ Like... Meat
              </h1>
              <p className="fs-3 mb-0 lh-1">
                You can cook Meati much like you cook animal meat: pan sear, bake or grill. Keep scrolling to learn more!
              </p>
            </div>
          </Col>
          <Col md={6}>
            <a href="#" onClick={() => handleOpen()}>
              <div className="stack-parent mb-1">
                <StaticImage
                  src="../../images/hero/dave-chang-meati.jpg"
                  quality={95}
                  placeholder="blurred"
                  alt="Dave Chang Cooking Meati"
                  layout="fullWidth"
                  className="rounded-5 meati-shadow-orange stack-child"
                  imgClassName="rounded-5"
                />
                <div className="stack-child d-flex align-items-center justify-content-center">
                  <img src={PlayCircle} alt="play button icon" className="video-play" />
                </div>
              </div>
              <p className="text-center fs-5 mb-0 d-flex justify-content-center align-items-center text-dark">
                <img src={PlayOutline} alt="play button icon" className="me-12" />
                Watch how Dave Chang does it
              </p>
            </a>
          </Col>
        </Row>
      </div>
      <Modal
        id="videoModal"
        open={open}
        onClose={handleClose}
        aria-labelledby="Watch How Dave Chang does it"
        aria-describedby="Watch video of Dave Chang"
        className="d-flex align-items-center"
        disableRestoreFocus
      >
        <div className="bg-dark modal-content mx-auto shadow" style={{ height: '90%', width: '94%', maxWidth: '94%' }}>
          <button className="btn btn-link close d-flex justify-content-end" onClick={handleClose}>
            <FontAwesomeIcon className="fa-2x" icon={faTimes} />
          </button>
          <Row className="g-0">
            <Col sm="12" className="pt-2 pt-sm-3 px-2 px-sm-3 d-flex flex-column">
              <iframe
                src="https://player.vimeo.com/video/689497993?h=453eeb0189&autoplay=1&color=00B84F&title=0&byline=0&portrait=0&playsinline=false"
                style={iframeStyle}
                title="David Chang Meati Video"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
              <script src="https://player.vimeo.com/api/player.js" />
            </Col>
          </Row>

        </div>
      </Modal>
    </header>
  )
}

export default KitchenHero
