import * as React from 'react'
import RecipeKnife from '../svg-icons/recipe-knife'
import RecipeChefHat from '../svg-icons/recipe-chef-hat'

const RecipeTimes = (props) => {
  const justify = props.justify === 'center' ? 'justify-content-center' : 'justify-content-start'
  if (props.prepTime || props.cookTime) {
    return (
      <div className={`d-flex recipe-times flex-wrap ${justify}`}>
        {props.prepTime
        && (
        <div className="mx-12 d-flex align-items-center">
          <RecipeKnife />
          Prep:
          {' '}
          <b className="ms-14">
            {props.prepTime}
            {' '}
            min
          </b>
        </div>
        )}
        {props.cookTime
        && (
        <div className="mx-12 d-flex align-items-center">
          <RecipeChefHat />
          Cook:
          {' '}
          <b className="ms-14">
            {props.cookTime}
            {' '}
            min
          </b>
        </div>
        )}
      </div>
    )
  }
}

export default RecipeTimes
