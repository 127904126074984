import * as React from 'react'
import Container from 'react-bootstrap/Container'
import { Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import Modal from '@mui/material/Modal'
import Button from 'react-bootstrap/Button'
import QuestionForm from '../common/question-form'

const HowDoYouMeati = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <section className="py-2 py-md-3 py-lg-4 py-xl-5 bg-dark text-white">
      <Container className="d-flex flex-column">
        <Row className="align-items-center g-md-3">
          <Col md={6}>
            <StaticImage
              src="../../images/sections/how-do-you-meati.jpg"
              quality={95}
              placeholder="blurred"
              alt="Hero Image"
              layout="fullWidth"
              className="mb-1 mb-md-0 rounded-6 meati-shadow-orange"
              imgClassName="rounded-6"
            />
          </Col>
          <Col md={6} className="text-center text-md-start">
            <h2 className="fw-bolder" style={{ fontSize: '6vw' }}>
              how do you Meati?
            </h2>
            <p className="fs-4 mb-2">Share your favorite recipes with us here!</p>

            <Button
              variant="primary"
              className="btn-lg ad-el-tracked"
              data-ad-element-type="cta button"
              data-ad-text="share recipe"
              data-ad-button-id="version 1"
              data-ad-button-location="body"

              onClick={
                () => handleOpen()
              }
            >
              share recipe
            </Button>

            <Modal
              id="shareRecipeModal"
              open={open}
              onClose={handleClose}
              aria-labelledby="Share recipe"
              aria-describedby="Share recipe"
              className="d-flex align-items-center"
              disableRestoreFocus
            >
              <div className="bg-light modal-content mx-auto shadow rounded-5">
                <button className="btn btn-link close d-flex justify-content-end" onClick={handleClose}>
                  <FontAwesomeIcon className="fa-2x" icon={faTimes} />
                </button>
                <QuestionForm title="Share recipe" messageLegend="Recipe" subjects="Recipe Recommendation" buttonText="Share Recipe" />
              </div>
            </Modal>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HowDoYouMeati
